// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

// Variables
@import 'variables';

// Third-party
@import '~bootstrap/scss/bootstrap';
@import '~react-toastify/dist/ReactToastify.min.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~react-datepicker/dist/react-datepicker.min.css';
@import '~react-lazy-load-image-component/src/effects/blur.css';

* {
    scroll-behavior: smooth;
}
label {
    cursor: inherit;
}

body {
    font-family: 'Open Sans', sans-serif;
    background-color: var(--grey);
}

body #root {
    // overflow-x: hidden;
}

a {
    color: inherit;

    &:focus {
        outline: 0;
    }
}

button {
    &:focus {
        outline: 0;
    }
}

::selection {
    color: var(--white);
    background-color: var(--accent);
}

html .bg-main {
    background-color: var(--grey);
}

html .bg-white {
    background-color: var(--light-grey);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.1rem rgba(var(--accent), 0.5);
}

html .btn {
    padding: 10px 21px;
}
html .btn > * {
    cursor: pointer;
}

html .btn.btn-primary {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: var(--white);
    transition: 145ms ease-in-out;
    background-color: var(--accent);
    border-color: var(--accent);
}

html .btn.btn-primary svg path {
    transition: 145ms ease-in-out;
}

html .btn.btn-primary:not(:disabled):hover,
html .btn.btn-primary:not(:disabled):active {
    border: 1px solid var(--accent);
    background-color: var(--white);
    color: var(--black);
}

html .btn.btn-primary:not(:disabled):hover svg path,
html .btn.btn-primary:not(:disabled):active svg path {
    fill: #3a3b36;
}

html .btn.btn-danger {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: var(--white);
    border-color: var(--danger);
}

html .btn.btn-danger:not(:disabled):hover,
html .btn.btn-danger:not(:disabled):active {
    border: 1px solid var(--danger);
    background-color: var(--white);
    color: var(--black);
}

html .btn.btn-primary:focus,
html .btn.btn-primary.focus {
    background-color: var(--accent);
    border-color: var(--accent);
    box-shadow: 0 0 0 0.1rem rgba(var(--accent), 0.5);
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: none;
}

.btn-link:disabled {
    cursor: not-allowed;
    pointer-events: unset;
}

.btn-link:not(:disabled):hover {
    color: #000;
    text-decoration: none;
}

.btn-link:disabled:hover {
    text-decoration: none;
}

.btn.btn-fill {
    width: 100%;
    display: inline-block;
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: var(--white);
    background: var(--accent);
    border-radius: 5px;
    border: 1px solid transparent;
    transition: all 0.5s ease;

    &:disabled {
        cursor: not-allowed;
    }

    &:not(:disabled):hover {
        color: var(--black);
        background-color: var(--white);
        border-color: var(--accent);
    }
}

.btn.btn-empty {
    width: 100%;
    display: inline-block;
    padding: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: var(--black);
    background: var(--white);
    border-radius: 5px;
    border: 1px solid var(--black);
    transition: all 0.5s ease;

    &:disabled {
        cursor: not-allowed;
    }

    &:not(:disabled):hover {
        color: var(--black);
        background-color: var(--white);
        border-color: var(--accent);
    }
}

.btn.btn-empty-yellow:first-of-type {
    width: 100%;
    display: inline-block;
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-decoration: none;
    color: var(--accent);
    background: transparent;
    border-radius: 5px;
    border: 1px solid var(--accent);
    transition: all 0.5s ease;
    opacity: 0.5;
}

.width-240 {
    width: 240px;
}

.disabled-link {
    pointer-events: none;
}

.overlay {
    z-index: 20;
    position: fixed;
    background: rgba(0, 0, 0, 0.2);
    will-change: opacity;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay img {
    display: block;
    cursor: pointer;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.slick-list {
    border-radius: 10px;
}

.btn.btn-empty-yellow:first-of-type:hover {
    opacity: 1;
}

.accordion > .card {
    overflow: visible;
}

.card {
    border: 0;
    // margin-bottom: 15px;
}

.accordion > .card > .card-header {
    margin-bottom: 15px;
}

.card-header {
    padding: 0;
    background: var(--white) url('/svg/chevron-down.svg') right center no-repeat;
    border: 0;
}

.react-datepicker {
    &-wrapper {
        width: 100%;
    }

    &-popper {
        z-index: 2;
    }

    &__input-container {
        input {
            transition: 0.5s all;
            font-weight: 500;
            padding: 11px 20px;
            display: block;
            width: 100%;
            font-size: 12px;
            line-height: 16px;
            color: var(--dark-grey);
            background: #eeeeee;
            border-radius: 5px;
            border: 1px solid #eee;
        }

        input:focus {
            border: 1px solid var(--dark-grey);
            outline: none;
        }
    }
}

.card-header button {
    padding: 0;
    width: 100%;
    display: inline-block;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    color: #281605;
    text-decoration: none;
}

.card-header button:not(:disabled):hover {
    color: #000;
    text-decoration: none;
}

.card-header button:focus {
    box-shadow: none;
}

.card-body {
    padding: 10px;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #281605;
}

.label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--dark-grey);
    margin-bottom: 10px;
    display: block;
}

// Tabs

.nav-tabs {
    border-bottom: 0;
    padding: 0;
    background-color: var(--dark-grey);
    border-radius: 10px 10px 0 0;
}

.nav-tabs .nav-link {
    width: 50%;
    padding: 20px;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    color: #989898;
    text-align: center;
    background: #545454;
    border: 1px solid transparent;
    border-radius: 0 0 0 10px;
    border-color: #545454;
}

.nav-tabs .nav-link:hover {
    border-color: transparent;
}

.nav-tabs .nav-link.active {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: var(--accent);
    text-align: center;
    background: var(--dark-grey);
    border-radius: 0;
    border-radius: 0 0 0 10px;
    border-color: var(--dark-grey);
}

.nav-tabs .nav-link:first-child:not(.active) {
    border-radius: 0 0 10px 0;
}

.tab-content {
    background: var(--dark-grey);
    border-radius: 0 0 10px 10px;
}

.orders-tabs .nav-tabs {
    position: relative;
    padding: 0 0 0px;
    background-color: transparent;
    border-radius: 0;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        z-index: 2;
        background-color: #ebebeb;
        transition: all 0.3s linear;
    }
}

.lk-header {
    padding: 10px 20px;
    margin: 0 0 25px;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #3a3b36;
    background: var(--white);
    border-radius: 10px;
}

.orders-tabs .nav-tabs .nav-link {
    position: relative;
    width: auto;
    padding: 0 0 10px;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    color: var(--textcolor);
    background-color: transparent;
    border: none;
    border-radius: 0;

    &::before {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 100%;
        height: 1px;
        z-index: 10;
        background-color: var(--accent);
        transform: scale(0);
        transition: transform 0.3s linear;
    }
}

.orders-tabs .nav-tabs .nav-link:first-of-type {
    margin-right: 30px;
}

.orders-tabs .nav-tabs .nav-link.active {
    font-weight: bold;

    &::before {
        transform: scale(1);
    }
}

.orders-tabs .tab-content {
    background-color: transparent;
    border: 0;
}

.orders-tabs .tab-content > .nav-tabs {
    border: 0;
}

// Modal

.modal {
    &-lg {
        max-width: 500px;
    }

    &-content {
        padding: 30px 100px 50px 75px;
        background: var(--dark-grey);
        border-radius: 10px;
    }

    &-header {
        padding: 0;
        border: none;
        border-radius: 0;

        .close {
            position: absolute;
            top: 20px;
            right: 20px;
            font-weight: 300;
            font-size: 35px;
            color: var(--accent);
            text-shadow: none;
            transition: opacity 0.5s;
        }
    }

    &-title {
        .h4 {
            margin: 0 0 15px;
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            color: var(--accent);
        }
    }

    &-body {
        padding: 0;
        border: none;

        .p {
            max-width: 350px;
            font-weight: 300;
            font-size: 16px;
            line-height: 22px;
            color: #eeeeee;
        }

        .submit {
            margin-bottom: 20px;
            padding: 10px 20px;
            width: 100%;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            color: var(--white);
            background: #fcb602;
            border-radius: 5px;
            border: none;
            transition: background-color 0.3s linear;

            &:hover {
                background-color: #ff9d29;
            }
        }
    }

    &-footer {
        justify-content: center;
        padding: 0;
        border: none;

        > * {
            margin: 0;
        }

        a {
            display: block;
            margin-bottom: 25px;
            font-size: 14px;
            line-height: 19px;
            color: var(--white);
        }

        span {
            display: block;
            font-weight: 300;
            font-size: 14px;
            line-height: 19px;
            color: #eeeeee;
        }
    }
}

.size-800 .modal-dialog {
    max-width: 800px;
}

.white-modal {
    .modal-content {
        padding: 45px 100px 45px 75px;
        background: var(--light-grey);
        border-radius: 10px;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    }

    .modal-body p {
        color: #000;
    }

    .modal-header {
        padding: 0;
        border: none;
        border-radius: 0;
        color: #000;

        .close {
            position: absolute;
            top: 20px;
            right: 20px;
            font-weight: 300;
            font-size: 35px;
            color: var(--dark-grey);
            text-shadow: none;
            transition: opacity 0.5s;
        }
    }
}

// Table

.table-bordered {
    border: 0;
}

.table thead th {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    border: 0;
    color: #3a3b36;
}

.table th,
.table td {
    padding: 1rem 1.75rem;
}

//.table th:first-of-type {
//  width: 15%;
//  padding-left: 0;
//}
//
//.table th:nth-of-type(2) {
//  width: 28%;
//}
//
//.table th:nth-of-type(3) {
//  width: 17%;
//}
//
//.table th:nth-of-type(4) {
//  width: 20%;
//}

.orders-table {
    padding: 0 30px;
}

.orders-table table {
    margin-bottom: 0;
}

.orders-table .react-bootstrap-table table th {
    padding-top: 12px;
    padding-bottom: 8px;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: var(--textcolor);
    border-bottom: 1px solid #ebebeb;
}

.react-bootstrap-table tfoot {
    font-size: 12px;
}

.orders-table .react-bootstrap-table table th:first-of-type {
    width: 25%;
    padding-left: 0;
}

.orders-table .react-bootstrap-table table th:nth-of-type(2) {
    width: 25%;
}

.orders-table .react-bootstrap-table table th:nth-of-type(3) {
    width: 25%;
}

.orders-table .react-bootstrap-table table th:nth-of-type(4) {
    width: 25%;
}

.orders-table .react-bootstrap-table table td {
    padding-top: 15px;
    padding-bottom: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    //color: #989898;
    border: 0;
}

.orders-table .react-bootstrap-table table td:first-of-type {
    padding-left: 0;
}

.orders-table .react-bootstrap-table table td:last-of-type {
    padding-right: 0;
}

.orders-table .react-bootstrap-table table tr:last-of-type td {
    padding-bottom: 17px;
}

.slick-dots {
    bottom: 15px;
}

.filter-label {
    margin-bottom: 0;
}

.react-datepicker-popper {
    z-index: 10;
}

.filter-label select {
    font-size: 14px;
    border: 0;
    height: auto;
    padding: 0;

    font-style: normal;
    font-weight: 300;
    line-height: 19px;
    color: #3a3b36;
}

.slick-dots li button:before {
    color: #c4c4c4;
    font-size: 12px;
    opacity: 1;
}

.slick-dots li.slick-active button:before {
    color: var(--accent);
}

.slick-prev,
.slick-next {
    width: 30px;
    height: 30px;
}

.slick-prev {
    left: 30px;
    z-index: 1;
}

.slick-next {
    right: 30px;
    z-index: 1;
}

.slick-prev:before,
.slick-next:before {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    font: 0;
    opacity: 1;
}

.slick-prev:before {
    background: url('/svg/chevron-left.svg') center no-repeat;
}

.slick-next:before {
    background: url('/svg/chevron-right.svg') center no-repeat;
}

// bootstrap-table

.react-bootstrap-table th .order-4:before {
    margin-left: 3.5px;
    content: '\2191';
    opacity: 0.4;
}

.react-bootstrap-table th .order-4:after {
    content: '\2193';
    opacity: 0.4;
}

.react-bootstrap-table th .caret-4-desc:before {
    margin-left: 3.5px;
    content: '\2191';
    opacity: 0.4;
}

.react-bootstrap-table th .caret-4-desc:after {
    content: '\2193';
}

.react-bootstrap-table th .caret-4-asc:before {
    margin-left: 3.5px;
    content: '\2191';
}

.react-bootstrap-table th .caret-4-asc:after {
    content: '\2193';
    opacity: 0.4;
}

.bootstrap-table {
    table th:first-of-type {
        padding-left: 0.75rem;
    }

    table .sortable {
        cursor: pointer;
    }

    .product-image img {
        max-width: 35px;
        width: 100%;
        height: 35px;
        object-fit: cover;
    }

    table tbody tr td {
        font-size: 12px;
        vertical-align: middle;
    }

    table tbody tr {
        cursor: pointer;
        transition: 145ms ease-in-out;
    }

    table tbody tr:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    table th,
    table td {
        padding: 0.75rem;
    }

    ul {
        margin: 0;
    }

    .page-item.active .page-link {
        background-color: #484848;
        border-color: #484848;
    }

    .page-link {
        color: #000;
        padding: 0.2rem 0.45rem;
    }

    .dropdown-toggle {
        padding: 10px;
        line-height: 1;
        margin-left: 15px;
        margin-bottom: 15px;
        background-color: #484848;
        border-color: #484848;
    }
}

// Toastify

.Toastify {
    &__toast-container {
        min-width: 350px;
        max-width: 500px;

        &--top-right {
            top: 7em;
            right: 1em;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #3a405f;
        margin-bottom: 3px;
    }

    &__desc {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #949599;
        margin-bottom: 0;
    }

    &__toast {
        background: var(--white);
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        padding: 10px 10px 10px 10px;

        font-size: 12px;
        line-height: 16px;
        color: #281605;
        position: relative;
    }

    &__close-button {
        color: #646464;
    }

    &__toast--success {
        background: #fff;
    }

    &__toast--success:before {
        content: '';
        position: absolute;
        height: 30px;
        width: 1px;
        background: #ebebeb;
        border-radius: 5px;
        left: 35px;
        top: 0;
        bottom: 0;
        margin: auto 0;
    }

    &__toast--success:after {
        content: '';
        position: absolute;
        height: 15px;
        width: 15px;
        background: url('/svg/noty-success.svg');
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto 0;
    }

    &__progress-bar--success {
        background-color: #46b044;
    }

    &__toast--warning {
        background: #fff;
    }

    &__toast--warning:before {
        content: '';
        position: absolute;
        height: 30px;
        width: 1px;
        background: #ebebeb;
        border-radius: 5px;
        left: 35px;
        top: 0;
        bottom: 0;
        margin: auto 0;
    }

    &__toast--warning:after {
        content: '';
        position: absolute;
        height: 15px;
        width: 15px;
        background: url('/svg/noty-warning.svg');
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto 0;
    }

    &__progress-bar--warning {
        background-color: #fcb602;
    }

    &__toast--error {
        background: #fff;
    }

    &__toast--error:before {
        content: '';
        position: absolute;
        height: 30px;
        width: 1px;
        background: #ebebeb;
        border-radius: 5px;
        left: 35px;
        top: 0;
        bottom: 0;
        margin: auto 0;
    }

    &__toast--error:after {
        content: '';
        position: absolute;
        height: 15px;
        width: 15px;
        background: url('/svg/noty-error.svg');
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto 0;
    }

    &__progress-bar--error {
        background-color: #b04444;
    }

    &__toast-body {
        background: #fff;
        padding-left: 45px;
    }
}

// Navbar

.navbar {
    padding: 0;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('/svg/menu-open.svg');
}

.navbar-light .navbar-toggler {
    border-color: #c4c4c4;
}

.navbar-toggler {
    margin-left: auto;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #c4c4c4;
}

.navbar-toggler-icon {
    width: 22px;
    height: 22px;
}

.navbar-collapse.show {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark-grey);
}

.accordion:not(.full-height) {
    .collapse {
        overflow-y: hidden;
        // min-height: 60vh;
        // padding-bottom: 30vh;
    }
}

input[type='text']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type='text']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

input[type='search'] {
    -webkit-appearance: none;

    &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        background-image: url('/svg/search-clear.svg');
        margin-right: 25px;
        width: 15px;
        border-radius: 50%;
        border: 1px solid #c4c4c4;
        height: 15px;
        background-size: 10px;
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    transition: all 0.3s;
    border-radius: 8px;
}

.sliders-img {
    background: none !important;
    overflow: hidden;
    border-radius: 10px 10px 20px 20px;
}

.lazy-load-image-background {
    background: #f4f4f4;
}

.user-table .table th,
.user-table .table td {
    padding: 0.5rem;
}

.fade-enter {
    opacity: 1;
    transition: 750ms;
}

.fade-enter-active {
    opacity: 1;
    transition: 750ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: 750ms;
}

.product-image .lazy-load-image-background {
    width: auto !important;
    display: table !important;
}

@import 'media';
