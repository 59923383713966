@media (max-width: 992px) {
    .slick-arrow {
        display: none !important;
    }

    .overlay img {
        max-width: 90%;
    }

    .modal-content .accordion .card:not(.mb-0):last-of-type .card-header {
        border-bottom: 0;
    }

    .Toastify__toast {
        box-shadow: 0 5px 20px rgb(0 0 0 / 30%);
    }

    .slick-list,
    .nav-tabs,
    .tab-content {
        border-radius: 0;
    }

    .Toastify__toast-container--top-right {
        margin: auto;
        right: 0;
        left: 0;
    }

    .Toastify__toast-container {
        width: 94%;
        top: 5em;
    }

    .width-240 {
        width: 150px;
    }

    .slick-track,
    .slick-slider {
        max-height: fit-content;
    }

    .product-filter {
        .modal-dialog {
            margin: 0;
        }
    }

    .white-modal .modal-content {
        padding: 45px 25px;
    }
}

@media (max-width: 768px) {
    .nav-tabs .nav-link {
        padding: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: var(--black);
        background-color: var(--white);
        border-color: var(--accent);
        border-radius: 0;
    }

    .nav-tabs .nav-link.active {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: var(--white);
        background-color: var(--accent);
        border-color: var(--accent);
        border-radius: 0;
    }

    .nav-tabs .nav-link:first-child:not(.active) {
        border-radius: 0;
    }

    .orders-table {
        padding: 0 20px;
    }

    .orders-table > .react-bootstrap-table {
        overflow-x: scroll;
    }

    .orders-table .react-bootstrap-table table th {
        border-bottom: 1px solid #e2e2e2;
    }

    .orders-table .react-bootstrap-table table td {
        font-size: 12px;
    }

    .orders-table .react-bootstrap-table table th,
    .orders-table .react-bootstrap-table table td {
        white-space: nowrap;
        padding: 0.5rem 1rem;
    }

    .orders-tabs .nav-tabs .nav-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        padding: 10px;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
    }

    .orders-tabs .nav-tabs .nav-link:first-of-type {
        margin-right: 0;
    }
}

@media (max-width: 992px) {
    .card-header button,
    .modal-content .card-header button {
        color: #281605;
    }
    .modal-content .accordion:not(.full-height) .card .collapse.show > div {
        width: 100%;
        margin: auto;
    }

    .Toastify__toast {
        margin-bottom: 10px;
    }

    .modal-content
        .accordion:not(.full-height)
        .card
        .collapse.show
        > div:last-of-type {
        border-bottom: 0;
    }

    .modal-content {
        padding: 40px;
    }

    // Filter modal
    .product-filter {
        .modal-content {
            padding: 0;
            background-color: #fff;
            border: 0;
            min-height: 100vh;
            height: auto;
            border-radius: 0;

            .accordion {
                .card {
                    margin-bottom: 0;

                    &:last-of-type {
                        margin-bottom: 25px;
                    }

                    .collapse {
                        overflow-y: scroll;
                        max-height: 160px;
                    }

                    .collapse.show > div {
                        border-bottom: 1px solid #ebebeb;
                        padding-bottom: 10px;
                    }
                }

                .card-header {
                    padding: 5px 20px 10px;
                    margin-bottom: 10px;
                    border-bottom: 1px solid #ebebeb;
                    background: var(--white) url('/svg/chevron-down.svg') right
                        20px center no-repeat;
                }
            }
        }
    }
}

/*--------------------------------------------------------------
## Notebook
--------------------------------------------------------------*/
@media only screen and (max-width: 1280px) {
    html {
        font-size: 92%;
    }
}

/*--------------------------------------------------------------
## Netbook
--------------------------------------------------------------*/
@media only screen and (max-width: 1024px) {
    html {
        font-size: 90%;
    }
}

/*--------------------------------------------------------------
## iPad
--------------------------------------------------------------*/
@media only screen and (max-width: 991px) {
    html {
        font-size: 85%;
    }
}

/*--------------------------------------------------------------
## iPad
--------------------------------------------------------------*/
@media only screen and (max-width: 768px) {
    html {
        font-size: 80%;
    }
}

/*--------------------------------------------------------------
## iPad
--------------------------------------------------------------*/
@media only screen and (max-width: 480px) {
    html {
        font-size: 75%;
    }
}
