.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.wrapper {
    padding-top: 60px;
}

.pb {
    padding-bottom: 60px;
}

.loader {
    margin: auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 182, 2, 0.2);
    border-right: 1.1em solid rgba(255, 182, 2, 0.2);
    border-bottom: 1.1em solid rgba(255, 182, 2, 0.2);
    border-left: 1.1em solid var(--accent);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

.loader p {
    position: absolute;
    margin: auto;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.loading {
    position: absolute;
    margin: auto auto 20px auto;
    bottom: 0;
    left: 0;
    right: 0;
    display: table;
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #7b7b7b;
}

.loading span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    font-size: 28px;
}

.saving span:nth-child(2) {
    animation-delay: .2s;
}

.saving span:nth-child(3) {
    animation-delay: .4s;
}

@keyframes blink {
    0% {
        opacity: .2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}

@-webkit-keyframes blink {
    0% {
        opacity: .2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
